var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', [_c('v-alert', {
    attrs: {
      "dense": "",
      "type": "error",
      "dismissible": ""
    }
  }, [_vm._v(" 该模块为鑫泰能源结算系统配置及运维集中管理的区域，仅向具有相应权限的员工开发使用。 ")]), _c('v-row', {
    staticClass: "fill-height overflow-y-auto"
  }, [_vm.currentUser.hasAdmin.includes('distributor') || _vm.currentUser.permission.includes('System_Organize') ? _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-card', {
    attrs: {
      "outlined": ""
    }
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('v-img', {
    staticClass: "ma-2",
    attrs: {
      "src": "/static/card/organize.svg",
      "height": "100px",
      "contain": ""
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "8"
    }
  }, [_c('v-card-title', [_vm._v(" 组织权限 ")]), _c('v-card-text', [_vm._v("鑫泰能源公司内部人员及组织及权限管理")])], 1)], 1), _c('v-divider'), _c('v-list-item', {
    attrs: {
      "dense": ""
    }
  }, [_c('div', {
    staticClass: "text-truncate"
  }, [_c('v-btn', {
    attrs: {
      "text": "",
      "link": "",
      "to": "/distributor/system/organize/department"
    }
  }, [_vm._v("组织架构")]), _c('v-btn', {
    attrs: {
      "text": "",
      "link": "",
      "to": "/distributor/system/organize/roles"
    }
  }, [_vm._v("角色权限")])], 1), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "icon": "",
      "link": "",
      "to": "/distributor/system/organize/department"
    }
  }, [_c('v-icon', [_vm._v("arrow_forward")])], 1)], 1)], 1)], 1) : _vm._e(), _vm.currentUser.hasAdmin.includes('distributor') || _vm.currentUser.permission.includes('System_Area') ? _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-card', {
    attrs: {
      "outlined": ""
    }
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('v-img', {
    staticClass: "ma-2",
    attrs: {
      "src": "/static/card/space.svg",
      "height": "100px",
      "contain": ""
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "8"
    }
  }, [_c('v-card-title', [_vm._v(" 区域管理 ")]), _c('v-card-text', [_vm._v("对区域命名和所含省市进行维护。")])], 1)], 1), _c('v-divider'), _c('v-list-item', {
    attrs: {
      "dense": ""
    },
    on: {
      "click": function click($event) {
        return _vm.$router.push({
          path: '/distributor/system/area'
        });
      }
    }
  }, [_c('v-spacer'), _c('v-icon', {
    staticClass: "subheading font-weight-light"
  }, [_vm._v("arrow_forward")])], 1)], 1)], 1) : _vm._e(), _vm.currentUser.hasAdmin.includes('distributor') || _vm.currentUser.permission.includes('System_Task') ? _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-card', {
    attrs: {
      "outlined": ""
    }
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('v-img', {
    staticClass: "ma-2",
    attrs: {
      "src": "/static/card/wfsetting.svg",
      "height": "100px",
      "contain": ""
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "8"
    }
  }, [_c('v-card-title', [_vm._v(" 流程管理 ")]), _c('v-card-text', [_vm._v("指定各业务组结算流程的执行人。")])], 1)], 1), _c('v-divider'), _c('v-list-item', {
    attrs: {
      "dense": ""
    },
    on: {
      "click": function click($event) {
        return _vm.$router.push({
          path: '/distributor/system/task'
        });
      }
    }
  }, [_c('v-spacer'), _c('v-icon', {
    staticClass: "subheading font-weight-light"
  }, [_vm._v("arrow_forward")])], 1)], 1)], 1) : _vm._e(), _vm.currentUser.hasAdmin.includes('distributor') ? _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-card', {
    attrs: {
      "outlined": ""
    }
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('v-img', {
    staticClass: "ma-2",
    attrs: {
      "src": "/static/card//bug.svg",
      "height": "100px",
      "contain": ""
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "8"
    }
  }, [_c('v-card-title', [_vm._v(" 问题反馈 ")]), _c('v-card-text', [_vm._v("系统BUG和建议反馈")])], 1)], 1), _c('v-divider'), _c('v-list-item', {
    attrs: {
      "dense": ""
    },
    on: {
      "click": function click($event) {
        return _vm.$router.push({
          path: '/distributor/system/bugs'
        });
      }
    }
  }, [_c('v-spacer'), _c('v-icon', {
    staticClass: "subheading font-weight-light"
  }, [_vm._v("arrow_forward")])], 1)], 1)], 1) : _vm._e(), _vm.currentUser.hasAdmin.includes('distributor') ? _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-card', {
    attrs: {
      "outlined": ""
    }
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('v-img', {
    staticClass: "ma-2",
    attrs: {
      "src": "/static/card/releasesetting.svg",
      "height": "100px",
      "contain": ""
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "8"
    }
  }, [_c('v-card-title', [_vm._v(" 发行说明 ")]), _c('v-card-text', [_vm._v("系统版本说明")])], 1)], 1), _c('v-divider'), _c('v-list-item', {
    attrs: {
      "dense": ""
    },
    on: {
      "click": function click($event) {
        return _vm.$router.push({
          path: '/distributor/system/release'
        });
      }
    }
  }, [_c('v-spacer'), _c('v-icon', {
    staticClass: "subheading font-weight-light"
  }, [_vm._v("arrow_forward")])], 1)], 1)], 1) : _vm._e(), _vm.currentUser.hasAdmin.includes('distributor') || _vm.currentUser.permission.includes('System_Logs') ? _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-card', {
    attrs: {
      "outlined": ""
    }
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('v-img', {
    staticClass: "ma-2",
    attrs: {
      "src": "/static/card/logs.svg",
      "height": "100px",
      "contain": ""
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "8"
    }
  }, [_c('v-card-title', [_vm._v(" 日志分析 ")]), _c('v-card-text', [_vm._v("系统操作日志查阅")])], 1)], 1), _c('v-divider'), _c('v-list-item', {
    attrs: {
      "dense": ""
    },
    on: {
      "click": function click($event) {
        return _vm.$router.push({
          path: '/distributor/system/logs'
        });
      }
    }
  }, [_c('v-spacer'), _c('v-icon', {
    staticClass: "subheading font-weight-light"
  }, [_vm._v("arrow_forward")])], 1)], 1)], 1) : _vm._e(), _vm.currentUser.hasAdmin.includes('distributor') ? _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-card', {
    attrs: {
      "outlined": ""
    }
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('v-img', {
    staticClass: "ma-2",
    attrs: {
      "src": "/static/card/test.svg",
      "height": "100px",
      "contain": ""
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "8"
    }
  }, [_c('v-card-title', [_vm._v(" 开发测试 ")]), _c('v-card-text', [_vm._v("供开发工程师进行技术验证和测试的模块")])], 1)], 1), _c('v-divider'), _c('v-list-item', {
    attrs: {
      "dense": ""
    },
    on: {
      "click": function click($event) {
        return _vm.$router.push({
          path: '/distributor/system/test'
        });
      }
    }
  }, [_c('v-spacer'), _c('v-icon', {
    staticClass: "subheading font-weight-light"
  }, [_vm._v("arrow_forward")])], 1)], 1)], 1) : _vm._e()], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }