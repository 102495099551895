<template>
  <v-container>
    <v-alert dense type="error" dismissible>
      该模块为鑫泰能源结算系统配置及运维集中管理的区域，仅向具有相应权限的员工开发使用。
    </v-alert>
    <v-row class="fill-height overflow-y-auto">
      <!--组织权限-->
      <v-col cols="6" v-if="currentUser.hasAdmin.includes('distributor') || currentUser.permission.includes('System_Organize')">
        <v-card outlined>
          <v-row align="center">
            <v-col cols="4">
              <v-img src="/static/card/organize.svg" height="100px" contain class="ma-2"></v-img>
            </v-col>
            <v-col cols="8">
              <v-card-title>
                组织权限
              </v-card-title>
              <v-card-text>鑫泰能源公司内部人员及组织及权限管理</v-card-text>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-list-item dense>
            <div class="text-truncate">
              <v-btn text link to="/distributor/system/organize/department">组织架构</v-btn>
              <v-btn text link to="/distributor/system/organize/roles">角色权限</v-btn>
            </div>
            <v-spacer></v-spacer>
            <v-btn icon link to="/distributor/system/organize/department"><v-icon>arrow_forward</v-icon></v-btn>
          </v-list-item>
        </v-card>
      </v-col>

      <!--区域设置-->
      <v-col cols="6" v-if="currentUser.hasAdmin.includes('distributor') || currentUser.permission.includes('System_Area')">
        <v-card outlined>
          <v-row align="center">
            <v-col cols="4">
              <v-img src="/static/card/space.svg" height="100px" contain class="ma-2"></v-img>
            </v-col>
            <v-col cols="8">
              <v-card-title>
                区域管理
              </v-card-title>
              <v-card-text>对区域命名和所含省市进行维护。</v-card-text>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-list-item dense @click="$router.push({path:'/distributor/system/area'})">
            <v-spacer></v-spacer>
            <v-icon class="subheading font-weight-light">arrow_forward</v-icon>
          </v-list-item>
        </v-card>
      </v-col>

      <!--流程设置-->
      <v-col cols="6" v-if="currentUser.hasAdmin.includes('distributor') || currentUser.permission.includes('System_Task')">
        <v-card outlined>
          <v-row align="center">
            <v-col cols="4">
              <v-img src="/static/card/wfsetting.svg" height="100px" contain class="ma-2"></v-img>
            </v-col>
            <v-col cols="8">
              <v-card-title>
                流程管理
              </v-card-title>
              <v-card-text>指定各业务组结算流程的执行人。</v-card-text>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-list-item dense @click="$router.push({path:'/distributor/system/task'})">
            <v-spacer></v-spacer>
            <v-icon class="subheading font-weight-light">arrow_forward</v-icon>
          </v-list-item>
        </v-card>
      </v-col>

      <!--问题反馈-->
      <v-col cols="6" v-if="currentUser.hasAdmin.includes('distributor')">
        <v-card outlined>
          <v-row align="center">
            <v-col cols="4">
              <v-img src="/static/card//bug.svg" height="100px" contain class="ma-2"></v-img>
            </v-col>
            <v-col cols="8">
              <v-card-title>
                问题反馈
              </v-card-title>
              <v-card-text>系统BUG和建议反馈</v-card-text>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-list-item dense @click="$router.push({path:'/distributor/system/bugs'})">
            <v-spacer></v-spacer>
            <v-icon class="subheading font-weight-light">arrow_forward</v-icon>
          </v-list-item>
        </v-card>
      </v-col>

      <!--发行说明-->
      <v-col cols="6" v-if="currentUser.hasAdmin.includes('distributor')">
        <v-card outlined>
          <v-row align="center">
            <v-col cols="4">
              <v-img src="/static/card/releasesetting.svg" height="100px" contain class="ma-2"></v-img>
            </v-col>
            <v-col cols="8">
              <v-card-title>
                发行说明
              </v-card-title>
              <v-card-text>系统版本说明</v-card-text>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-list-item dense @click="$router.push({path:'/distributor/system/release'})">
            <v-spacer></v-spacer>
            <v-icon class="subheading font-weight-light">arrow_forward</v-icon>
          </v-list-item>
        </v-card>
      </v-col>

      <!--日志分析-->
      <v-col cols="6" v-if="currentUser.hasAdmin.includes('distributor') || currentUser.permission.includes('System_Logs')">
        <v-card outlined>
          <v-row align="center">
            <v-col cols="4">
              <v-img src="/static/card/logs.svg" height="100px" contain class="ma-2"></v-img>
            </v-col>
            <v-col cols="8">
              <v-card-title>
                日志分析
              </v-card-title>
              <v-card-text>系统操作日志查阅</v-card-text>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-list-item dense @click="$router.push({path:'/distributor/system/logs'})">
            <v-spacer></v-spacer>
            <v-icon class="subheading font-weight-light">arrow_forward</v-icon>
          </v-list-item>
        </v-card>
      </v-col>

      <!--开发测试-->
      <v-col cols="6" v-if="currentUser.hasAdmin.includes('distributor')">
        <v-card outlined>
          <v-row align="center">
            <v-col cols="4">
              <v-img src="/static/card/test.svg" height="100px" contain class="ma-2"></v-img>
            </v-col>
            <v-col cols="8">
              <v-card-title>
                开发测试
              </v-card-title>
              <v-card-text>供开发工程师进行技术验证和测试的模块</v-card-text>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-list-item dense @click="$router.push({path:'/distributor/system/test'})">
            <v-spacer></v-spacer>
            <v-icon class="subheading font-weight-light">arrow_forward</v-icon>
          </v-list-item>
        </v-card>
      </v-col>

    </v-row>
  </v-container>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  data() {
    return {}
  },
  created() {
  },
  computed: {
    ...mapGetters(['currentUser']),
  },
  watch: {},
  mounted() {},
  methods: {}
}
</script>
